<template>
  <div class="upgrade-page">
    <div class="upgrade-page__wrap">
      <div class="upgrade-page__icon">
        <ArrowUpgrade />
      </div>

      <div class="upgrade-page__title">
        {{ $t("upgrade-title") }}
      </div>

      <div class="upgrade-page__caption">
        {{ $t("upgrade-caption") }} {{ characterAvailableSkillPointsCopy }}
        {{ $t("upgrade-points") }}
      </div>
    </div>

    <CharCard
      :character-image="characterImage"
      :nft-id="nftId"
      :character-name="characterName"
    />

    <div class="upgrade-page__list" v-if="characterSkills">
      <div
        class="upgrade-page__item"
        v-for="([prop, value], index) in Object.entries(characterSkills)"
        :key="index"
      >
        <StatListItem
          v-if="prop !== '_id'"
          :stat-title="$t(`stat-${prop}`)"
          :stat-amount="value"
        />

        <div class="toggle-amount" v-if="prop !== '_id'">
          <button
            class="toggle-amount__btn"
            @click="toggleSkillPoint('minus', prop)"
            :disabled="
              !usedSkillPoints.includes(prop) || !usedSkillPoints.length
            "
          >
            <RoundedButton>
              <template v-slot:icon>
                <MinusSvg />
              </template>
            </RoundedButton>
          </button>

          <button class="toggle-amount__btn">
            <RoundedButton
              @click="toggleSkillPoint('plus', prop)"
              :disabled="
                usedSkillPoints.includes(prop) ||
                usedSkillPoints.length ===
                  (characterAvailableSkillPoints
                    ? characterAvailableSkillPoints
                    : 2)
              "
            >
              <template v-slot:icon>
                <PlusSvg />
              </template>
            </RoundedButton>
          </button>
        </div>
      </div>
    </div>

    <button
      class="buy-btn"
      @click="isCurrencyModal = true"
      :disabled="!nextUpgradeAmount || usedSkillPoints.length !== 2"
    >
      <CoinGoldSvg />
      {{ $t("buy-upgrade-btn") }}
    </button>
    <transition name="fade">
      <CurrencyModal
        v-if="isCurrencyModal && nextUpgradeAmount"
        :cost-igm="nextUpgradeAmount.igm || 0"
        :cost-sub="nextUpgradeAmount.sub || 0"
        @close="isCurrencyModal = false"
        @selectCurrency="selectCurrency"
      />
    </transition>
  </div>
</template>

<script>
import ArrowUpgrade from "../components/icons/ArrowUpgrade";
import StatListItem from "../components/common/StatListItem";
import RoundedButton from "../components/common/buttons/RoundedButton";
import MinusSvg from "../components/icons/MinusSvg";
import PlusSvg from "../components/icons/PlusSvg";
import CoinGoldSvg from "../components/icons/CoinGoldSvg";
import CurrencyModal from "../components/modals/CurrencyModal";
import LoadShoppieByIdMixin from "@/mixins/loadShoppieByIdMixin";
import api from "@/api/api";
import { mapActions, mapGetters } from "vuex";
import SetProcessingMixin from "@/mixins/general/SetProcessingMixin";
import errors from "@/utils/errors";
import MakeSignMixin from "@/mixins/sign/MakeSignMixin";
import CharCard from "../components/character/CharCard";

export default {
  name: "Upgrade",
  mixins: [LoadShoppieByIdMixin, SetProcessingMixin, MakeSignMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isCurrencyModal: false,
    usedSkillPoints: [],
    characterAvailableSkillPointsCopy: 0,
  }),
  computed: {
    ...mapGetters({
      getWallet: "wallet/getWallet",
    }),
  },
  methods: {
    ...mapActions({
      loadBalance: "wallet/loadBalance",
    }),
    async buySkillPoint(nextUpgradeAmount, currency) {
      if (!this.getWallet || (this.getWallet && !this.getWallet.privateKey)) {
        this.setError(errors.NEED_WALLET_RESTORE);
        this.isCurrencyModal = false;
        return;
      }
      try {
        this.setLoading(true);
        if (!this.characterAvailableSkillPoints && nextUpgradeAmount) {
          let body = null;
          if (currency === "sub") {
            body = {
              sig: await this.makeSign(nextUpgradeAmount),
            };
          }
          await api.buySkillPoint(body, this.id);
        }

        for (const i of this.usedSkillPoints) {
          await api.upgradeCsh(this.id, i);
        }
        await this.loadBalance();
        this.isCurrencyModal = false;
        this.setSuccess({
          title: "Successfully upgraded!",
          message: "",
          redirectTo: "/home",
        });
      } catch (e) {
        console.log(e);
        this.setError({
          title: "",
          message: e.response.data.error.message,
        });
      } finally {
        this.setLoading(false);
      }
    },
    async selectCurrency(currency) {
      let amount = 0;
      if (currency === "igm") {
        amount = this.nextUpgradeAmount?.igm;
      } else {
        amount = this.nextUpgradeAmount?.sub;
      }
      await this.buySkillPoint(amount, currency);
    },
    toggleSkillPoint(operation, skill) {
      if (operation === "minus") {
        this.characterAvailableSkillPointsCopy++;
        this.usedSkillPoints = this.usedSkillPoints.filter((i) => i !== skill);
        this.characterSkills[skill]--;
      } else {
        this.characterAvailableSkillPointsCopy--;
        this.usedSkillPoints.push(skill);
        this.characterSkills[skill]++;
      }
    },
  },
  async mounted() {
    this.setLoading(true);
    await this.getCryptoshoppiesById(this.id);
    this.characterAvailableSkillPointsCopy =
      this.characterAvailableSkillPoints || 2;
    this.setLoading(false);
  },
  components: {
    CharCard,
    CurrencyModal,
    CoinGoldSvg,
    PlusSvg,
    MinusSvg,
    RoundedButton,
    StatListItem,
    ArrowUpgrade,
  },
};
</script>

<style scoped></style>
