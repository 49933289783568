<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-currency">
        <div class="popup-swipe" v-touch:swipe.bottom="close">
          <span></span>
        </div>
        <div class="popup__head">
          <div class="popup__title">
            {{ $t("currency-title") }}
          </div>

          <PageButton @click="close">
            <template v-slot:icon>
              <Close />
            </template>
          </PageButton>
        </div>

        <div class="popup__content">
          <div class="currency-wrap" @click="selectCurrency('sub')">
            <div class="currency-wrap__notice">
              {{ $t("currency-notice-one") }}
            </div>

            <div class="d-flex">
              <div class="currency-wrap__icon">
                <CoinGoldSvg />
              </div>
              <div class="currency-wrap__text">
                <div class="currency-wrap__title">
                  SUB {{ balanceBeforeDot(costSub) }}.<span>{{
                    balanceAfterDot(costSub)
                  }}</span>
                </div>

                <div class="currency-wrap__caption">
                  {{ $t("currency-caption") }}
                </div>
              </div>

              <RoundedButton>
                <template v-slot:icon>
                  <ArrowRight />
                </template>
              </RoundedButton>
            </div>
          </div>

          <div
            class="currency-wrap"
            @click="selectCurrency('igm')"
            :class="{ 'currency-wrap_disabled': !costIgm }"
          >
            <div class="currency-wrap__notice">
              {{ $t("currency-notice-five") }}
            </div>

            <div class="d-flex">
              <div class="currency-wrap__icon">
                <CoinSilverSvg />
              </div>

              <div class="currency-wrap__text">
                <div class="currency-wrap__title">
                  IGM {{ balanceBeforeDot(costIgm) }}.<span>{{
                    balanceAfterDot(costIgm)
                  }}</span>
                </div>

                <div class="currency-wrap__caption">
                  {{ $t("currency-caption") }}
                </div>
              </div>

              <RoundedButton>
                <template v-slot:icon>
                  <ArrowRight />
                </template>
              </RoundedButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageButton from "../common/buttons/PageButton";
import Close from "../icons/Close";
import CoinGoldSvg from "../icons/CoinGoldSvg";
import RoundedButton from "../common/buttons/RoundedButton";
import ArrowRight from "../icons/ArrowRight";
import CoinSilverSvg from "../icons/CoinSilverSvg";
import { mapActions } from "vuex";
import CloseModalMixin from "@/mixins/common/CloseModalMixin";
import FormatPriceMixin from "@/mixins/common/FormatPriceMixin";

export default {
  name: "CurrencyModal",
  mixins: [CloseModalMixin, FormatPriceMixin],
  props: {
    costIgm: {
      required: true,
    },
    costSub: {
      required: true,
    },
  },
  methods: {
    ...mapActions({
      loadBalance: "wallet/loadBalance",
    }),
    selectCurrency(currency) {
      if (currency === "igm" && !this.costIgm) {
        return;
      }
      this.$emit("selectCurrency", currency);
    },
  },
  mounted() {
    this.loadBalance();
  },
  components: {
    CoinSilverSvg,
    ArrowRight,
    RoundedButton,
    CoinGoldSvg,
    Close,
    PageButton,
  },
};
</script>

<style scoped></style>
