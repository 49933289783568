var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upgrade-page"},[_c('div',{staticClass:"upgrade-page__wrap"},[_c('div',{staticClass:"upgrade-page__icon"},[_c('ArrowUpgrade')],1),_c('div',{staticClass:"upgrade-page__title"},[_vm._v(" "+_vm._s(_vm.$t("upgrade-title"))+" ")]),_c('div',{staticClass:"upgrade-page__caption"},[_vm._v(" "+_vm._s(_vm.$t("upgrade-caption"))+" "+_vm._s(_vm.characterAvailableSkillPointsCopy)+" "+_vm._s(_vm.$t("upgrade-points"))+" ")])]),_c('CharCard',{attrs:{"character-image":_vm.characterImage,"nft-id":_vm.nftId,"character-name":_vm.characterName}}),(_vm.characterSkills)?_c('div',{staticClass:"upgrade-page__list"},_vm._l((Object.entries(_vm.characterSkills)),function(ref,index){
var prop = ref[0];
var value = ref[1];
return _c('div',{key:index,staticClass:"upgrade-page__item"},[(prop !== '_id')?_c('StatListItem',{attrs:{"stat-title":_vm.$t(("stat-" + prop)),"stat-amount":value}}):_vm._e(),(prop !== '_id')?_c('div',{staticClass:"toggle-amount"},[_c('button',{staticClass:"toggle-amount__btn",attrs:{"disabled":!_vm.usedSkillPoints.includes(prop) || !_vm.usedSkillPoints.length},on:{"click":function($event){return _vm.toggleSkillPoint('minus', prop)}}},[_c('RoundedButton',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('MinusSvg')]},proxy:true}],null,true)})],1),_c('button',{staticClass:"toggle-amount__btn"},[_c('RoundedButton',{attrs:{"disabled":_vm.usedSkillPoints.includes(prop) ||
              _vm.usedSkillPoints.length ===
                (_vm.characterAvailableSkillPoints
                  ? _vm.characterAvailableSkillPoints
                  : 2)},on:{"click":function($event){return _vm.toggleSkillPoint('plus', prop)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('PlusSvg')]},proxy:true}],null,true)})],1)]):_vm._e()],1)}),0):_vm._e(),_c('button',{staticClass:"buy-btn",attrs:{"disabled":!_vm.nextUpgradeAmount || _vm.usedSkillPoints.length !== 2},on:{"click":function($event){_vm.isCurrencyModal = true}}},[_c('CoinGoldSvg'),_vm._v(" "+_vm._s(_vm.$t("buy-upgrade-btn"))+" ")],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isCurrencyModal && _vm.nextUpgradeAmount)?_c('CurrencyModal',{attrs:{"cost-igm":_vm.nextUpgradeAmount.igm || 0,"cost-sub":_vm.nextUpgradeAmount.sub || 0},on:{"close":function($event){_vm.isCurrencyModal = false},"selectCurrency":_vm.selectCurrency}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }