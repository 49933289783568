import contract from "@/api/contract";

export default {
  methods: {
    async makeSign(amount) {
      const signature = await contract.sign(amount);
      let r = signature.slice(0, 66);
      let s = "0x".concat(signature.slice(66, 130));
      let v = "0x".concat(signature.slice(130, 132));
      v = parseInt(v);
      if (![27, 28].includes(v)) v += 27;

      return {
        functionSignature: "0x",
        sigR: r,
        sigS: s,
        sigV: v,
        nonce: 0,
      };
    },
  },
};
