<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M41.2801 28.72L31.1734 42.1866H16.2134C13.6534 42.1866 12.3734 39.0933 14.1867 37.28L28.0001 23.4666C30.2134 21.2533 33.8134 21.2533 36.0267 23.4666L41.2801 28.72Z"
      fill="#CAA8F5"
    />
    <path
      d="M47.7867 42.1864H31.1733L41.28 28.7197L49.84 37.2797C51.6267 39.0931 50.3467 42.1864 47.7867 42.1864Z"
      fill="#CAA8F5"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowUpgrade",
};
</script>

<style scoped></style>
