<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 9C18 13.9705 14.3263 18 9.79483 18C5.26315 18 1.58984 13.9705 1.58984 9C1.58984 4.02932 5.26315 -7.83639e-08 9.79483 -7.83639e-08C14.3263 -7.83639e-08 18 4.02932 18 9"
      fill="#BBC1C3"
    />
    <path
      d="M7.66016 -7.83639e-08H9.84818V18H7.66016V-7.83639e-08Z"
      fill="#BBC1C3"
    />
    <path
      d="M16.4102 9C16.4102 13.9705 12.7365 18 8.20499 18C3.6733 18 0 13.9705 0 9C0 4.02932 3.6733 -7.83639e-08 8.20499 -7.83639e-08C12.7365 -7.83639e-08 16.4102 4.02932 16.4102 9"
      fill="#DDE8EC"
    />
    <path
      d="M7.94827 11.3108C9.76526 9.88921 11.056 8.11541 11.408 5.92339C11.6509 4.41071 12.0899 2.48072 11.704 0.859107C10.6426 0.30914 9.45684 0.000398941 8.20499 0.000398941C3.6733 0.000398941 0 4.02971 0 9.0004C0 11.032 0.614049 12.9058 1.6491 14.4121C3.95996 13.7954 6.11172 12.7476 7.94827 11.3108Z"
      fill="#EBF0F2"
    />
    <path
      d="M14.2568 8.83308C14.2568 12.6531 11.4844 15.75 8.06406 15.75C4.64369 15.75 1.87109 12.6531 1.87109 8.83308C1.87109 5.01288 4.64369 1.91617 8.06406 1.91617C11.4844 1.91617 14.2568 5.01288 14.2568 8.83308Z"
      fill="#A7AAAA"
    />
    <path
      d="M14.5586 8.99986C14.5586 12.7279 11.873 15.75 8.56024 15.75C5.24786 15.75 2.5625 12.7279 2.5625 8.99986C2.5625 5.27199 5.24786 2.24991 8.56024 2.24991C11.873 2.24991 14.5586 5.27199 14.5586 8.99986Z"
      fill="#CFD6DA"
    />
    <path
      d="M10.3353 5.03906L8.69899 5.31587C8.98665 5.58665 9.19486 6.01711 9.25435 6.65575L10.3353 6.54079V7.27669L9.26433 7.35771C9.16512 8.79339 8.36182 9.68184 7.09221 9.69826C7.86575 10.7905 8.70897 12.257 9.54181 13.8418L8.58979 13.646C7.81625 12.2488 7.09221 11.0347 6.16016 9.73147V8.97805L6.70553 8.97495C7.77672 8.96911 8.35184 8.35838 8.42131 7.42158L6.16016 7.59237V6.98474L8.39156 6.74753C8.25262 5.98261 7.72701 5.67698 6.86423 5.81493L6.16016 5.92751V5.13759L10.3353 4.30298V5.03906"
      fill="#BBC1C3"
    />
    <path
      d="M10.1988 4.87641L8.56246 5.1658C8.84992 5.43258 9.05832 5.85846 9.11781 6.49273L10.1988 6.3692V7.10109L9.12779 7.19032C9.02858 8.61815 8.22529 9.50715 6.95568 9.53324C7.72943 10.6126 8.57224 12.0637 9.40547 13.6328L8.45327 13.4447C7.67972 12.0616 6.95568 10.8605 6.02344 9.5732V8.82507L6.56901 8.81778C7.63999 8.80354 8.21531 8.19226 8.28458 7.26075L6.02344 7.44943V6.846L8.25504 6.59127C8.1161 5.83237 7.59049 5.5333 6.72771 5.678L6.02344 5.79606V5.0118L10.1988 4.14451V4.87641"
      fill="#EBF0F2"
    />
  </svg>
</template>

<script>
export default {
  name: "CoinSilverSvg",
};
</script>

<style scoped></style>
