import { render, staticRenderFns } from "./ArrowUpgrade.vue?vue&type=template&id=2fc3f5c0&scoped=true&"
import script from "./ArrowUpgrade.vue?vue&type=script&lang=js&"
export * from "./ArrowUpgrade.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc3f5c0",
  null
  
)

export default component.exports