<template>
  <svg
    width="10"
    height="2"
    viewBox="0 0 10 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.92004 1.75H0.920044C0.510044 1.75 0.170044 1.41 0.170044 1C0.170044 0.59 0.510044 0.25 0.920044 0.25H8.92004C9.33004 0.25 9.67004 0.59 9.67004 1C9.67004 1.41 9.34004 1.75 8.92004 1.75Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "MinusSvg",
};
</script>

<style scoped></style>
